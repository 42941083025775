import React, { useLayoutEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { isLoggedIn } from "../services/auth"
import { navigate } from "gatsby"

export default function Template({
  data, location // this prop will be injected by the GraphQL query below.
}) {

  useLayoutEffect(() => {
    checkLoginStatus()
    // return () => {
    //   cleanup
    // };
  }, [])

  const { kontentItemTutorial } = data // data.markdownRemark holds your post data
  //const { node } = kontentItemTutorial.elements
  // console.log($slug)
  // const { frontmatter, html } = kontentItemTutorial
  
  const checkLoginStatus = () => {
    if (!isLoggedIn() && location.pathname !== `/login`) {
      navigate("/login")
      return null
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      {/* <header id="header">
        <a href="index.html" class="logo"><strong>Editorial</strong> by HTML5 UP</a>
        <ul class="icons">
          <li><a href="#" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>
          <li><a href="#" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
          <li><a href="#" class="icon brands fa-snapchat-ghost"><span class="label">Snapchat</span></a></li>
          <li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
          <li><a href="#" class="icon brands fa-medium-m"><span class="label">Medium</span></a></li>
        </ul>
      </header> */}

      <section id="banner">
        <div className="content">
          <header>
            <h1>{kontentItemTutorial.elements["title"].value}</h1>
          </header>
          <div dangerouslySetInnerHTML={{ __html: kontentItemTutorial.elements["content"].value }} />
          </div>
        </section>
      </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    kontentItemTutorial(elements: {slug: {value: {eq: $slug}}}) {
      elements {
        title {
          value
        }
        content {
          value
        }
      }
    }
  }  
`